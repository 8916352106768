import React, { useState, useEffect } from "react";
import { getHttps, deleteHttps } from "../../hooks/useHttps";
import Button from "../../components/Button";
import ProductForm from "../../layouts/ProductForm";
import styles from "./styles.module.css";
import UploadImage from "../../layouts/UploadImageForm";

interface CardBoxProductProps {
  workplace: string;
}

function CardBoxProduct({ workplace }: CardBoxProductProps) {
  const [childrenDataArr, setChildrenDataArr] = useState([]);

  const getAllProducts = async () => {
    const data = await getHttps("/product/get-all", {
      workplaceId: workplace,
    });
    setChildrenDataArr(data.products);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div>
      <div className={styles.container}>
        {childrenDataArr.map((item: any) => {
          return (
            <div className={styles.box}>
              <ProductCard workplace={workplace} key={item._id} obj={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface ProductCardProps {
  obj: any;
  workplace: string;
}
function ProductCard({ obj, workplace }: ProductCardProps) {
  const [formVisible, setFormVisible] = useState(false);
  const [feedBack, setFeedBack] = useState(""); //feedback

  useEffect(() => {
    //feedback
    if (feedBack) {
      //feedback
      setTimeout(() => {
        //feedback
        setFeedBack(""); //feedback
      }, 1000); //feedback
    } //feedback //feedback
  }, [feedBack]); //feedback


  const onDelete = async (id: string) => {
    console.log("Delete clicked", id);
    const response = await deleteHttps("/product/delete", {
      id,
    });
    console.log("delete product response: ", response);

    if (response.status === "success") {
      //feedback
      setFeedBack("urun silindi"); //feedback
    } else {
      //feedback
      setFeedBack("urun silinemedi"); //feedback
    } //feedback
  };

  if (formVisible) {
    return (
      <>
        <div className={styles.edit}>
          <ProductForm workplace={workplace} update={true} updateId={obj._id} />
        </div>
        <Button name="Geri" onClick={() => setFormVisible(false)} />
      </>
    );
  }

  return (
    <div>
      <h2 className={styles.name}>{obj.name}</h2>
      <p className={styles.aciklama}>Aciklama: {obj.desc}</p>
      <div className={styles.cardMidBox}>
        <UploadImage
          uniquekey={obj._id}
          workplace={workplace}
          placeholder={"Urun Fotolari"}
          path={"/product/upload-image"}
          product_category_id={obj._id}
          setFeedBack={setFeedBack} //feedback
          src={
            obj
            ? obj.image
              ? process.env.REACT_APP_BACKEND_URL +
                obj?.image.substring(8)
              : null
            : null
          }
        />
        <Button name="Düzenle" onClick={() => setFormVisible(true)} />
        <Button name="Sil" onClick={() => onDelete(obj._id)} />

        {
          feedBack !== "" && <div className={styles.success}>{feedBack}</div> //feedback
          //feedback
        }
      </div>
    </div>
  );
}

export default CardBoxProduct;
