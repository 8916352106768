import React from "react";
import styles from "./style.module.css";

interface ProductCardProps {
  title: string;
  price: string | number;
  src: string;
  alt: string;
  className?: string; 
}

const ProductCard: React.FC<ProductCardProps> = ({ title, price, src, alt, className }) => {
  return (
    <div className={`${styles.card} ${className}`}> 
      <div className={styles.cardImage}>
        <img src={src} alt={alt} />
      </div>
      <div className={styles.info}>
        <p className={styles.title}>{title}</p>
        <p className={styles.price}>{price}</p>
      </div>
    </div>
  );
};

export default ProductCard;



