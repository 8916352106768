import React from "react";

const DOMAIN = process.env.REACT_APP_IS_DEV
  ? process.env.REACT_APP_DOMAIN_DEV
  : process.env.REACT_APP_DOMAIN_PROD;

console.log("domain::", DOMAIN);
console.log(" is dev::", process.env.REACT_APP_IS_DEV);
console.log("typeof is dev::", typeof process.env.REACT_APP_IS_DEV);


//check if the domain (url) has a subdomain
const csd = () => {
  try {
    const fullUrl = window.location.href;
    const splittedArr = fullUrl.split("." + DOMAIN);
	console.log("full url", fullUrl);
	console.log("splitarr", splittedArr);

    if (splittedArr.length === 1) {
      // no subdomain
      console.log("here we are");
      return "no sub domain";
    } else if (splittedArr.length === 2) {
      // subdomain exists
      const preDomain = splittedArr[0];
      const subDomain = preDomain.split("//")[1];
      return subDomain;
    }
  } catch (err) {
    // console.log(err);
    return "€ℝℝøℝ";
  }
};

export { csd };
