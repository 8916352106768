import React from "react";
import styles from "./styles.module.css";

interface DescriptionBoxProps {
  description: string;
  fontSize?: string;
  fontColor?: string;
  backgroundColor?: string;
}

function DescriptionBox({
  description,
  fontSize,
  fontColor,
  backgroundColor
}: DescriptionBoxProps) {
  const style = {
    fontSize: fontSize,
    color: fontColor,
    backgroundColor: backgroundColor,
  };
  return (
    <div className={styles.container}>
      <div className={styles.desc} style={style}>
        {description ? description : "description"}
      </div>
    </div>
  );
}

export default DescriptionBox;
