import React from "react";
import styles from "./styles.module.css";

interface ContactTextProps {
  text: string;
  color?: string;
  fontSize?: string;
  fontStyle?: string;
  fontFamily?: string;
  id?: string;
}

function ContactText({
  text,
  color,
  fontSize,
  fontStyle,
  fontFamily,
  id,
}: ContactTextProps) {
  const style = {
    color: color,
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontFamily: fontFamily,
  };

  return (
    <div style={style} className={styles.text} id={id}>
      {text}
    </div>
  );
}

export default ContactText;
