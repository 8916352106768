import React from "react";

import MainPageManager from "./features/MainPageManager";

import WorkplacePageManager from "./features/WorkplacePageManager";
import WorkplaceProvider from "./context/workplace/WorkplaceProvider";
import { csd } from "./hooks/subDomain";

function App() {
  const subDom: string = csd() ?? "no sub domain";
  console.log(subDom);  
  if (subDom === "no sub domain") {
    return (
      <div>
        <MainPageManager />
      </div>
    );
  }

  return (
    <div>

      <WorkplaceProvider>
        <WorkplacePageManager subdomain={subDom} />
      </WorkplaceProvider>
    </div>
  );
}

export default App;
