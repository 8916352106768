import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useFormik } from "formik";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import ListSinglePick from "../../components/ListSinglePick";
import { getHttps, postHttps, putHttps } from "../../hooks/useHttps";

interface ProductFormProps {
  update: boolean;
  updateId?: string;
  workplace: string;
}

function ProductForm({ update, updateId, workplace }: ProductFormProps) {
  // VARIABLES
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const [categoryId, setCategoryId] = useState<{
    text1: string;
    text2?: string;
    key: string;
  } | null>(null);
  const [searchLSP, setSearchLSP] = useState<string>(""); // ListSinglePick  fetch search word
  const [listDataLSP, setListDataLSP] = useState<any[]>([]); //  ListSinglePick data bottom list data

  // HOOKS
  useEffect(() => {
    fetchSearchDataLSP();

    if (update) {
      fetchCurrentProductData();
    }
  }, []);

  // FETCHES SEARCH/LIST DATA FOR LIST SINGLE PICK
  const fetchSearchDataLSP = async (search?: string) => {
    const result = await getHttps("/category/get-all", {
      workplaceId: workplace,
      search: searchLSP,
    });
    const processedData = result.categories.map((item: any) => ({
      text1: item.name,
      key: item._id,
    }));
    setListDataLSP(processedData);
  };

  // HANDLERS
  const onSearchBtnClickLSP = () => {
    fetchSearchDataLSP(searchLSP);
  };

  // CREATE Product
  const onCreateProduct = async (values: any) => {
    const result = await postHttps("/product/create", {
      workplaceId: workplace,
      categoryId: categoryId?.key,
      ...values,
    });
  };

  // UPDATE Product
  const onUpdateProduct = async (values: any) => {
    const result = await putHttps("/product/update", {
      id: updateId,
      categoryId: categoryId?.key,
      ...values,
    });
    console.log("Update Product", result);
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      price: "0",
      desc: "",
    },
    onSubmit: async (values: any) => {
      if (update) {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 2000);
        onUpdateProduct(values);
      } else {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
          resetForm();
        }, 2000);
        onCreateProduct(values);
      }
    },
    //  validationSchema,
  });

  const fetchCurrentProductData = async () => {
    const result = await getHttps("/product/get", { id: updateId });
    const product = result.product;
    setFieldValue("name", product.name || "");
    setFieldValue("price", product.price || "0");
    setFieldValue("desc", product.desc || "");
    setCategoryId({
      text1: product.category.name,
      key: product.category._id,
    });
  };
  return (
    <div>
      <h1 className={styles.title}> Urun {update ? "Guncelle" : "Ekle"} </h1>
      <div className={styles.productForm}>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          {[
            { name: "name", placeholder: "Urun Adı" },
            { name: "price", placeholder: "0" },
            { name: "desc", placeholder: "Urun Açıklaması" },
          ].map((input) => (
            <div key={input.name}>
              <TextInput
                value={values[input.name as keyof typeof values]}
                name={input.name}
                placeholder={input.placeholder}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors[input.name as keyof typeof errors] &&
                touched[input.name as keyof typeof touched] && (
                  <div className="error">
                    {errors[input.name as keyof typeof errors]?.toString()}
                  </div>
                )}
            </div>
          ))}
          <div className={styles.input}>
            <ListSinglePick
              placeholder={"Kategori Seciniz"}
              value={categoryId}
              setValue={setCategoryId}
              search={searchLSP}
              setSearch={setSearchLSP}
              selectionDataArray={listDataLSP}
              onSearchBtnClick={onSearchBtnClickLSP}
            />
          </div>
          <div className={styles.btn}>
            <Button onClick={handleSubmit} name={"Kaydet"} />
            {isSubmitted && (
            <div className={styles.success}>Urun Kaydedildi!</div>
          )}
          </div>
        </form>
        {/* <hr /> */}
      </div>
    </div>
  );
}

export default ProductForm;
