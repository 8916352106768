// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.styles_container__UvdOu {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    gap: 2.5rem; 
  }
  
  .styles_box__GsgzT{
    border: 1px solid #333;
    margin: 1rem;
    padding: 1rem;
  }

  .styles_title__Hk0OE{
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .styles_navbar__Ad77h{
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
  }
  .styles_navbar__Ad77h p{
    margin: 0.5rem  1rem;
    color: black;
  }
  .styles_footer__nSMIZ{
    display: flex;
    flex-direction: column;
    align-items:center ;  
  }
  .styles_footer__nSMIZ p{
    margin: 0.5rem  1rem;
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/components/ColorPreview/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,oBAAoB;IACpB,YAAY;EACd;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,oBAAoB;IACpB,YAAY;EACd","sourcesContent":["\r\n.container {\r\n    display: flex;\r\n    /* flex-wrap: wrap; */\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 2.5rem; \r\n  }\r\n  \r\n  .box{\r\n    border: 1px solid #333;\r\n    margin: 1rem;\r\n    padding: 1rem;\r\n  }\r\n\r\n  .title{\r\n    text-align: center;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n\r\n  .navbar{\r\n    display: flex;\r\n    flex-direction: row;\r\n    padding: 0.5rem;\r\n  }\r\n  .navbar p{\r\n    margin: 0.5rem  1rem;\r\n    color: black;\r\n  }\r\n  .footer{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items:center ;  \r\n  }\r\n  .footer p{\r\n    margin: 0.5rem  1rem;\r\n    color: black;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__UvdOu`,
	"box": `styles_box__GsgzT`,
	"title": `styles_title__Hk0OE`,
	"navbar": `styles_navbar__Ad77h`,
	"footer": `styles_footer__nSMIZ`
};
export default ___CSS_LOADER_EXPORT___;
