import React, { useState, useContext, useEffect } from "react";
import ColorPicker from "../ColorPicker"; // Renk seçici bileşeni
import Button from "../Button"; // Buton bileşeni
import WorkplaceContext from "../../context/workplace/WorkplaceContext";
import { putHttps } from "../../hooks/useHttps";
import styles from "./styles.module.css";
import { useFormik } from "formik";

interface ColorPreviewProps {
  workplaceId: string;
}

function ColorPreview({ workplaceId }: ColorPreviewProps) {
  const { workplace, changeWorkplace } = useContext(WorkplaceContext)!;
  const [colorArray, setColorArray] = useState<string[]>(workplace.colors);

  // Function to handle color change
  const handleColorChange = (newColors: string[]) => {
    setColorArray(newColors); // Update local color array
    changeWorkplace({ workplace: { ...workplace, colors: newColors } }); // Update workplace colors
  };

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        num: "",
        address: "",
        subDomain: "",
        socialMediaLinks: "",
        colors: [],
      },
      onSubmit: () => {
        onUpdateColors();
      },
      //  validationSchema,
    });

  const onUpdateColors = async () => {
    try {
      console.log("Gönderilen renk dizisi:", colorArray);
      console.log("Gönderilen workplace id:", workplace);
      const response = await putHttps("/workplace/update-colors", {
        _id: workplaceId,
        colors: colorArray,
      });
      console.log("response", response);
      // if (!response.ok) {
      //   throw new Error("Ağ hatası: Renkler kaydedilemedi.");
      // }
      console.log("Renkler başarıyla kaydedildi:", response);
    } catch (error) {
      console.error("Hata::\n", error);
    }
  };

  return (
    <div>
      <ColorPicker colorArray={colorArray} setColorArray={handleColorChange} />{" "}
      {/* Renk seçici */}
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            backgroundColor: colorArray[0], // Header color
            border: "2px solid black",
            padding: "10px",
            margin: "10px",
          }}
          className={styles.navbar}
        >
          <p>Anasayfa</p>
          <p>Hakkimizda</p>
          <p>Kategoriler</p>
          <p>Ürünler</p>
          <p>İletişim</p>
        </div>
        <div
          style={{
            backgroundColor: colorArray[1], // Background color
            padding: "20px",
            margin: "10px",
            border: "2px solid black",
          }}
          className={styles.footer}
        >
          <p>Kurumsal</p>
          <p>İletişim</p>
          <p>Adres</p>
        </div>
        <button
          style={{
            backgroundColor: colorArray[2], // Button color
            color: "#fff",
            padding: "10px",
            border: "none",
            margin: "10px",
          }}
        >
          {/*bu neyin rengiydi hatirlayamadim xd */}
          Bu bir buton
        </button>
      </div>
      <Button onClick={handleSubmit} name={"Kaydet"} type={"submit"} />{" "}
      {/* Save Button */}
    </div>
  );
}

export default ColorPreview;
