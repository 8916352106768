// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card__tyFL1{
    width: 45vh;
    height: 20vh;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: all 0.3s;
    justify-content: center; 
}

.style_cardImage__ssaMO{
    display: flex;
    width: 50%;
}

.style_image__ddxuN{
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.style_card__tyFL1:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.style_textDiv__EttgL{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.style_nameC__Jzfta{
    margin: 0;
    font-size:2rem; 
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/components/CategoryCard/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,oBAAoB;IACpB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,4BAA4B;IAC5B,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,uCAAuC;AAC3C;AACA;IACI,aAAa;IACb,UAAU;IACV,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,cAAc;AAClB","sourcesContent":[".card{\r\n    width: 45vh;\r\n    height: 20vh;\r\n    display: flex;\r\n    flex-direction: row;\r\n    cursor: pointer;\r\n    transition: all 0.3s;\r\n    justify-content: center; \r\n}\r\n\r\n.cardImage{\r\n    display: flex;\r\n    width: 50%;\r\n}\r\n\r\n.image{\r\n    border-top-right-radius: 50%;\r\n    border-bottom-right-radius: 50%;\r\n    max-width: 100%;\r\n    max-height: 100%;\r\n    object-fit: contain;\r\n}\r\n\r\n.card:hover{\r\n    transform: scale(1.05);\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n.textDiv{\r\n    display: flex;\r\n    width: 50%;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n}\r\n\r\n.nameC{\r\n    margin: 0;\r\n    font-size:2rem; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `style_card__tyFL1`,
	"cardImage": `style_cardImage__ssaMO`,
	"image": `style_image__ddxuN`,
	"textDiv": `style_textDiv__EttgL`,
	"nameC": `style_nameC__Jzfta`
};
export default ___CSS_LOADER_EXPORT___;
