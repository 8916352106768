import React from "react";
import { Link } from "react-router-dom";

import styles from "./style.module.css";

interface NavbarItemProps {
  text: string;
  fontSize: string;
  url: string;
  backgroundColor?: string;
  onClick?: () => void;
}

function NavbarItem({
  text,
  fontSize,
  url,
  backgroundColor,
  onClick,
}: NavbarItemProps) {
  return (
    <div>
      <div
        className={styles.navbarItem}
        onClick={onClick}
        style={{ backgroundColor }}
      >
        <Link className={styles.text} to={url} style={{ fontSize }}>
          {text}
        </Link>
      </div>
    </div>
  );
}

export default NavbarItem;
