import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useFormik } from "formik";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import ListSinglePick from "../../components/ListSinglePick";
import { getHttps, postHttps, putHttps } from "../../hooks/useHttps";

interface CategoryFormProps {
  update: boolean;
  updateId?: string;
  workplace: string;
}

function CategoryForm({ update, updateId, workplace }: CategoryFormProps) {
  const [categories, setCategories] = useState([]);
  const [feedBack, setFeedBack] = useState(""); //feedback
  const [isSubmitted, setIsSubmitted] = useState(false);

  // HOOKS
  useEffect(() => {
    if (update) {
      fetchCurrentCategoryData();
    }
  }, []);

  // CREATE Category
  const onCreateCategory = async (values: any) => {
    const result = await postHttps("/category/create", {
      ...values,
      workplaceId: workplace,
    });
  };

  // UPDATE Category
  const onUpdateCategory = async (values: any) => {
    const result = await putHttps("/category/update", {
      id: updateId,
      ...values,
    });
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      desc: "",
    },
    onSubmit: async (values: any) => {
      if (update) {
        setFeedBack("kategori guncellendi"); //feedback
        onUpdateCategory(values);
      } else {
        setFeedBack("kategori eklendi"); //feedback
        resetForm();
        onCreateCategory(values);
      }
    },
    //  validationSchema,
  });

  const fetchCurrentCategoryData = async () => {
    const result = await getHttps("/category/get", { id: updateId });

    const category = result.category;
    setFieldValue("name", category.name || "");
    setFieldValue("desc", category.desc || "");
  };

  useEffect(() => {
    if (feedBack) {
      //feedback
      setTimeout(() => {
        //feedback
        setFeedBack(""); //feedback
      }, 1000); //feedback
    } //feedback //feedback
  }, [feedBack]); //feedback

  return (
    <div>
      <h1 className={styles.title}>Category {update ? "Guncelle" : "Ekle"}</h1>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        {[
          { name: "name", placeholder: "Kategori Adı" },
          { name: "desc", placeholder: "Kategori Açıklaması" },
        ].map((input) => (
          <div key={input.name}>
            <TextInput
              value={values[input.name as keyof typeof values]}
              name={input.name}
              placeholder={input.placeholder}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors[input.name as keyof typeof errors] &&
              touched[input.name as keyof typeof touched] && (
                <div className="error">
                  {errors[input.name as keyof typeof errors]?.toString()}
                </div>
              )}
          </div>
        ))}

        <div className={styles.btn}>
          <Button onClick={handleSubmit} name={"Kaydet"} />
          {
            feedBack !== "" && <div className={styles.success}>{feedBack}</div> //feedback
            //feedback
          }
        </div>
      </form>
    </div>
  );
}

export default CategoryForm;
