// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_productCard__RaLG9{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 360px;
    height: 120px;
    align-items: center;
    border-bottom: 1px solid black;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 10px;
    padding: 0 10px;
    
}

.style_productInfo__kDMjE{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    font-size: 20px;
}

.style_productInfo__kDMjE p{
    padding: 0 30px;
}

.style_productCard__RaLG9:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.style_img__7o10u{
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
    padding-left: 5px;
}

.style_cardImage__9JhdO{
    display: flex;
    align-items: center;
    font-size: 28px;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/features/site1/components/ProductCard/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;;AAEnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".productCard{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    flex-direction: row;\r\n    width: 360px;\r\n    height: 120px;\r\n    align-items: center;\r\n    border-bottom: 1px solid black;\r\n    cursor: pointer;\r\n    transition: all 0.3s;\r\n    border-radius: 10px;\r\n    padding: 0 10px;\r\n    \r\n}\r\n\r\n.productInfo{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    justify-items: center;\r\n    font-size: 20px;\r\n}\r\n\r\n.productInfo p{\r\n    padding: 0 30px;\r\n}\r\n\r\n.productCard:hover{\r\n    transform: scale(1.05);\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.img{\r\n    border-radius: 8px;\r\n    max-width: 80%;\r\n    max-height: 80%;\r\n    object-fit: contain;\r\n    padding-left: 5px;\r\n}\r\n\r\n.cardImage{\r\n    display: flex;\r\n    align-items: center;\r\n    font-size: 28px;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productCard": `style_productCard__RaLG9`,
	"productInfo": `style_productInfo__kDMjE`,
	"img": `style_img__7o10u`,
	"cardImage": `style_cardImage__9JhdO`
};
export default ___CSS_LOADER_EXPORT___;
