import React, { useState, useEffect } from "react";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import ListSinglePick from "../../components/ListSinglePick";
import { useFormik } from "formik";
import { getHttps, postHttps, putHttps } from "../../hooks/useHttps";
import ColorPicker from "../../components/ColorPicker";

interface WorkplaceFormProps {
  update: boolean;
  updateId?: string;
}

function WorkplaceForm({ update, updateId }: WorkplaceFormProps) {
  const [colorArray, setColorArray] = React.useState([] as string[]);
  // VARIABLES
  const [adminId, setAdminId] = useState<{
    text1: string;
    text2?: string;
    key: string;
  } | null>(null);
  const [searchLSP, setSearchLSP] = useState<string>(""); // ListSinglePick  fetch search word
  const [listDataLSP, setListDataLSP] = useState<any[]>([]); //  ListSinglePick data bottom list data

  // HOOKS
  useEffect(() => {
    fetchSearchDataLSP();

    if (update) {
      fetchCurrentWorkplaceData();
    }
  }, []);

  // FETCHES SEARCH/LIST DATA FOR LIST SINGLE PICK
  const fetchSearchDataLSP = async (search?: string) => {
    const result = await getHttps("/admin/get-all", { search: searchLSP });
    console.log("Admin get all", result.admins);
    const processedData = result.admins.map((item: any) => ({
      text1: item.name,
      key: item._id,
    }));
    setListDataLSP(processedData);
  };

  // HANDLERS
  const onSearchBtnClickLSP = () => {
    fetchSearchDataLSP(searchLSP);
  };

  // CREATE WORKPLACE
  const onCreateWorkplace = async (values: any) => {
    const result = await postHttps("/workplace/create", {
      ...values,
      colors: colorArray,     
    });
  };

  // UPDATE WORKPLACE
  const onUpdateWorkplace = async (values: any) => {
    const result = await putHttps("/workplace/update", {
      id: updateId,
      ...values,
    });
    console.log("Update workplace", result);
  };

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        num: "",
        address: "",
        subDomain: "",
        socialMediaLinks: "",
      },
      onSubmit: (values: any) => {
        if (update) {
          onUpdateWorkplace(values);
        } else {
          if (!adminId) {
            alert("Admin seciniz");
            return;
          }
          const data = { ...values, adminId: adminId?.key };
          onCreateWorkplace(data);
        }
      },
      //  validationSchema,
    });

  // FETCHES CURRENT WORKPLACE DATA AND SET TO FORM FOR UPDATE
  const fetchCurrentWorkplaceData = async () => {
    const resultWorkplace = await getHttps("/workplace/get", { id: updateId });
    console.log(resultWorkplace.workplace);

    if (!resultWorkplace.workplace) console.log("Workplace not found");

    values.name = resultWorkplace.workplace.name || "";
    values.email = resultWorkplace.workplace.email || "";
    values.num = resultWorkplace.workplace.num || "";
    values.address = resultWorkplace.workplace.address || "";
    values.subDomain = resultWorkplace.workplace.subDomain || "";
    values.socialMediaLinks = resultWorkplace.workplace.socialMediaLinks || "";
  };

  return (
    <div>
      <h1>Workplace {update ? "Guncelle" : "Ekle"}</h1>
      <form onSubmit={handleSubmit}>
        {[
          { name: "name", placeholder: "Workplace ismi" },
          { name: "email", placeholder: "email" },
          { name: "num", placeholder: "Telefon num" },
          { name: "address", placeholder: "Adres" },
          { name: "subDomain", placeholder: "Alt domain" },
          { name: "socialMediaLinks", placeholder: "Sosyal medya linkleri" },
        ].map((input) => (
          <div key={input.name}>
            <TextInput
              value={values[input.name as keyof typeof values]}
              name={input.name}
              placeholder={input.placeholder}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors[input.name as keyof typeof errors] &&
              touched[input.name as keyof typeof touched] && (
                <div className="error">
                  {errors[input.name as keyof typeof errors]?.toString()}
                </div>
              )}
          </div>
        ))}

        <ColorPicker colorArray={colorArray} setColorArray={setColorArray} />

        {update ? null : (
          <ListSinglePick
            placeholder={"Admin Seciniz"}
            value={adminId}
            setValue={setAdminId}
            search={searchLSP}
            setSearch={setSearchLSP}
            selectionDataArray={listDataLSP}
            onSearchBtnClick={onSearchBtnClickLSP}
          />
        )}{" "}
        <Button onClick={handleSubmit} name={"Kaydet"} />
      </form>
    </div>
  );
}

export default WorkplaceForm;
