// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_containergp__NMqhf {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  justify-content: space-between;
}
.style_Cards__S\\+-It {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* Responsive */
  width: 100%;
  max-width: 1200px;
  /* Maksimum genişlik sınırı */
  justify-items: center;
}

.style_CardsLeft__LjYoE {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  /* Her satırda 2 kart */
  width: 100%;
}

.style_ProductsImageBox__xG0ax {
  margin-top: 2rem;
  width: 100%;
  height: 200px;
  /* Örnek bir kutu, içerik için ayarlanabilir */
  background-color: #f5f5f5;
  border-radius: 0.5rem;
}

.style_categoryName__\\+94UR{
  text-align: center; 
}

/* Daha küçük ekranlar için düzenleme */
@media (max-width: 768px) {


  .style_CardsLeft__LjYoE {
    grid-template-columns: 1fr;
    justify-items: center;
    /* Mobilde her satırda 1 kart */
  }
}

@media (min-width: 1200px) {
  .style_container__1rasZ {
    height: 100vh;
  }
  .style_CardsLeft__LjYoE {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    /* Büyük ekranlarda 3 kart */
  }
}`, "",{"version":3,"sources":["webpack://./src/features/site2/pages/Products/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,SAAS;EACT,2DAA2D;EAC3D,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qCAAqC;EACrC,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,8CAA8C;EAC9C,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA,uCAAuC;AACvC;;;EAGE;IACE,0BAA0B;IAC1B,qBAAqB;IACrB,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,qCAAqC;IACrC,qBAAqB;IACrB,4BAA4B;EAC9B;AACF","sourcesContent":[".containergp {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 2rem;\r\n  min-height: 100vh;\r\n  justify-content: space-between;\r\n}\r\n.Cards {\r\n  display: grid;\r\n  gap: 2rem;\r\n  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\r\n  /* Responsive */\r\n  width: 100%;\r\n  max-width: 1200px;\r\n  /* Maksimum genişlik sınırı */\r\n  justify-items: center;\r\n}\r\n\r\n.CardsLeft {\r\n  display: grid;\r\n  gap: 2rem;\r\n  grid-template-columns: repeat(2, 1fr);\r\n  /* Her satırda 2 kart */\r\n  width: 100%;\r\n}\r\n\r\n.ProductsImageBox {\r\n  margin-top: 2rem;\r\n  width: 100%;\r\n  height: 200px;\r\n  /* Örnek bir kutu, içerik için ayarlanabilir */\r\n  background-color: #f5f5f5;\r\n  border-radius: 0.5rem;\r\n}\r\n\r\n.categoryName{\r\n  text-align: center; \r\n}\r\n\r\n/* Daha küçük ekranlar için düzenleme */\r\n@media (max-width: 768px) {\r\n\r\n\r\n  .CardsLeft {\r\n    grid-template-columns: 1fr;\r\n    justify-items: center;\r\n    /* Mobilde her satırda 1 kart */\r\n  }\r\n}\r\n\r\n@media (min-width: 1200px) {\r\n  .container {\r\n    height: 100vh;\r\n  }\r\n  .CardsLeft {\r\n    grid-template-columns: repeat(3, 1fr);\r\n    justify-items: center;\r\n    /* Büyük ekranlarda 3 kart */\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containergp": `style_containergp__NMqhf`,
	"Cards": `style_Cards__S+-It`,
	"CardsLeft": `style_CardsLeft__LjYoE`,
	"ProductsImageBox": `style_ProductsImageBox__xG0ax`,
	"categoryName": `style_categoryName__+94UR`,
	"container": `style_container__1rasZ`
};
export default ___CSS_LOADER_EXPORT___;
