import React, { useContext } from "react";
import styles from "./styles.module.css";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import ContactText from "../../components//ContactText";
import SocialIcon from "../../components/SocialIcon";
import {
  faInstagram,
  faSquareXTwitter,
  faFacebook,
  faLinkedin,
  faYoutube,
  faPinterest,
  faTiktok,
  faReddit,
  faWhatsapp,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";

function ContactInfo() {
  const { workplace, changeWorkplace } = useContext(WorkplaceContext);

  return (
    <div>
      <div className={styles.place}></div>

      <div className={styles.container}>
        <div className={styles.text}>
          <ContactText
            isTitle
            text="Bizi sosyal medya hesaplarımızdan takip edin" //SLOGAN
            fontSize="3vmin"
          />{" "}
        </div>

        <div className={styles.icons}>
          {workplace.socialMediaLinks &&
            workplace.socialMediaLinks.map((link, index) => {
              if (link !== null && link !== "") {
                const socialTypes = [
                  faInstagram,
                  faSquareXTwitter,
                  faFacebook,
                  faLinkedin,
                  faYoutube,
                  faPinterest,
                  faTiktok,
                  faReddit,
                  faWhatsapp,
                  faDiscord,
                ];
                return (
                  <SocialIcon
                    size="xl"
                    socialType={socialTypes[index]}
                    socialLink={link}
                    color={workplace.colors[4]}
                    key={index}
                  />
                );
              }
            })}
        </div>
        <div className={styles.text}>
          <ContactText isTitle text="Çalışma Saatlerimiz" fontSize="3vmin" />
          <br />
          <ContactText
            isTitle
            text={workplace.TextBlocks[1]}
            fontSize="3vmin"
          />
          {/*SAATLER*/}
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
