// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_fileInput__1Qr9f {
    display: none;
}

.styles_fileInputLabel__F8VKk {
    width: 160px;
    height: 160px;
    border-radius: 25px;
    border-style: dashed;
    border-color: #1E88E5;
    background-color: #f0f0ffaa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.styles_fileInputText__IWhvB {
    font-weight: 900;
    border-radius: 7px;
    padding-left: 5px;
    padding-right: 5px;
    color: #1E88E5;
    font-size: 1.25em;
    z-index: 999;
    background-color: #f0f0ffcc;
    text-align: center;
}

.styles_container__DVfUs {
    display: inline-block;
    position: relative;
    margin: 0.5rem;
}
.styles_img__cVoti {
    position: absolute;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 25px;
    /* z-index: 999; */
}

`, "",{"version":3,"sources":["webpack://./src/layouts/UploadImageForm/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;IACrB,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".fileInput {\r\n    display: none;\r\n}\r\n\r\n.fileInputLabel {\r\n    width: 160px;\r\n    height: 160px;\r\n    border-radius: 25px;\r\n    border-style: dashed;\r\n    border-color: #1E88E5;\r\n    background-color: #f0f0ffaa;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.fileInputText {\r\n    font-weight: 900;\r\n    border-radius: 7px;\r\n    padding-left: 5px;\r\n    padding-right: 5px;\r\n    color: #1E88E5;\r\n    font-size: 1.25em;\r\n    z-index: 999;\r\n    background-color: #f0f0ffcc;\r\n    text-align: center;\r\n}\r\n\r\n.container {\r\n    display: inline-block;\r\n    position: relative;\r\n    margin: 0.5rem;\r\n}\r\n.img {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    /* object-fit: cover; */\r\n    border-radius: 25px;\r\n    /* z-index: 999; */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `styles_fileInput__1Qr9f`,
	"fileInputLabel": `styles_fileInputLabel__F8VKk`,
	"fileInputText": `styles_fileInputText__IWhvB`,
	"container": `styles_container__DVfUs`,
	"img": `styles_img__cVoti`
};
export default ___CSS_LOADER_EXPORT___;
