import styles from "./styles.module.css";

interface ContactMapProps {
  borderStyles?: string;
  address: string;
}

function ContactMap({ borderStyles, address }: ContactMapProps) {

  const generateMapLink = (address: string ): string => {
    const encodedAddress = encodeURIComponent(address.trim()); // Adresi URL formatında kodla
    return `https://www.google.com/maps?q=${encodedAddress}&output=embed&z=18`;
  };
  
  const mapLink = generateMapLink(address);
  
  return (
    <div
      style={{
        border: borderStyles,
        overflow: "hidden",
      }}
      className={styles.mapin}
    >
      <iframe
        className={styles.iframe}
        src={mapLink}
      ></iframe>
    </div>
  );
}

export default ContactMap;
