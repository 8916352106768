import React from "react";

import styles from "./style.module.css";

interface ProductCardProps {
  image: string;
  alt: string;
  backgroundColor: string;
  productName: string;
  productPrice: string;
  width?: string;
  height?: string;
  fontSize?: string;
  onClick: () => void;
}

function ProductCard({
  image,
  alt,
  backgroundColor,
  productName,
  productPrice,
  width,
  height,
  fontSize,
  onClick,
}: ProductCardProps) {
  return (
    <div
      className={styles.productCard}
      style={{ backgroundColor, width, height}}
      onClick={onClick}
    >
      <div className={styles.cardImage}>
        <img className={styles.img} src={image} alt={alt} />
      </div>
      <div className={styles.productInfo}>
        <p className={styles.productName} style={{fontSize}}>{productName}</p>
        <p className={styles.productPrice} style={{fontSize}}>{productPrice}</p>
      </div>
    </div>
  );
}

export default ProductCard;
