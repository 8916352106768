import React from "react";
import styles from "./styles.module.css";
import TextInput from "../../components/TextInput/TextInput";
import { useFormik } from "formik";
import Button from "../../components/Button";
import { postHttps } from "../../hooks/useHttps";

interface LoginFormProps {
  isSuper: boolean;
  setRole: (role: string) => void;
}

function LoginForm({ isSuper, setRole }: LoginFormProps) {
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: async (values: any) => {
        const response = await postHttps(
          isSuper ? "/customer/login" : "/admin/login",
          values
        );
        setRole(response.role);
      },
      //  validationSchema,
    });

  return (
    <div>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <h1 className={styles.title}>Giris Yap</h1>
        <TextInput
          value={values.email}
          name="email"
          placeholder="Email"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {/*eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {errors.email && touched.email && (
          <div className="error">{errors.email.toString()}</div>
        )}

        <TextInput
          value={values.password}
          name="password"
          placeholder="sifre"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
        />
        {/*eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {errors.password && touched.password && (
          <div className="error">{errors.password.toString()}</div>
        )}
        <Button onClick={handleSubmit} name="Giris"></Button>
      </form>
    </div>
  );
}

export default LoginForm;
