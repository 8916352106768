import React, { useContext } from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import NavbarItem from "../../components/NavbarItem";
import NavbarLogo from "../../components/NavbarLogo";
import NavbarMenuIcon from "../../components/NavbarMenuIcon";

import styles from "./style.module.css";

interface NavbarProps {
  color?: string;
  backgroundColor: string;
}

function Navbar({ color, backgroundColor }: NavbarProps) {
  const { workplace } = useContext(WorkplaceContext);
  // console.log("workplace", workplace);
  const pages = [
    {
      text: "ANASAYFA",
      fontSize: "20px",
      url: "/",
      onClick: () => console.log("clicked"),
    },
    {
      text: "HAKKINDA",
      fontSize: "20px",
      url: "/about",
      onClick: () => console.log("clicked"),
    },
    {
      text: "KATEGORİLER",
      fontSize: "20px",
      url: "/categories",
      onClick: () => console.log("clicked"),
    },
    {
      text: "ÜRÜNLER",
      fontSize: "20px",
      url: "/products",
      onClick: () => console.log("clicked"),
    },
    {
      text: "İLETİŞİM",
      fontSize: "20px",
      url: "/contact",
      onClick: () => console.log("clicked"),
    },
  ];
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <nav
      style={{ backgroundColor: workplace.colors[0] }}
      className={styles.navbarBox}
    >
      <a className={styles.navbarLogo}>
        <NavbarLogo
          isSquare={false}
          image={
            workplace !== undefined && workplace !== null
              ? workplace.logo !== undefined && workplace.logo !== null
                ? workplace.logo !== ""
                  ? ( process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL_PROD ) +
                    workplace.logo.substring(8)
                  : "https://via.placeholder.com/1080"
                : "https://via.placeholder.com/1080"
              : "https://via.placeholder.com/1080"
          }
          height="6rem"
          width="6rem"
          alt="logo-512x512"
          onClick={() => console.log("clicked")}
        />
      </a>
      <div
        className={styles.navbarItemsPlusLine}
      >
        <div
          style={{
            backgroundColor: workplace.colors[0],
            display: mobileNavOpen || window.innerWidth > 767 ? "flex" : "none",
          }}
          className={styles.navbarItems}
        >
          {pages.map((page, index) => (
            <a key={index}>
              <NavbarItem
                text={page.text}
                fontSize={page.fontSize}
                url={page.url}
                onClick={page.onClick}
              />
            </a>
          ))}
        </div>
        <div className={styles.line} />
      </div>
      <div className={styles.navbarIcon}>
        <NavbarMenuIcon
          color="white"
          size="lg"
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen);
          }}
        />
      </div>
    </nav>
  );
}

export default Navbar;
