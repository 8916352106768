import React from "react";
import styles from "./styles.module.css";
import { useFormik } from "formik";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { putHttps } from "../../hooks/useHttps";

interface SocialLinksFormProps {
  workplace: string;
  setFeedBack: any;
  default: string[];
}

function SocialLinksForm({
  workplace,
  setFeedBack,
  default: defaultTexts,
}: SocialLinksFormProps) {
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        txt1: defaultTexts[0] ? defaultTexts[0] : "",
        txt2: defaultTexts[1] ? defaultTexts[1] : "",
        txt3: defaultTexts[2] ? defaultTexts[2] : "",
        txt4: defaultTexts[3] ? defaultTexts[3] : "",
        txt5: defaultTexts[4] ? defaultTexts[4] : "",
        txt6: defaultTexts[5] ? defaultTexts[5] : "",
        txt7: defaultTexts[6] ? defaultTexts[6] : "",
        txt8: defaultTexts[7] ? defaultTexts[7] : "",
        txt9: defaultTexts[8] ? defaultTexts[8] : "",
        txt10: defaultTexts[9] ? defaultTexts[9] : "",
      },
      onSubmit: async (values: any) => {
        const textBlocksArray = Object.values(values);

        const result = await putHttps("/workplace/set-social", {
          id: workplace,
          socialMediaLinks: textBlocksArray,
        });
        if (result.status === "success") {
          setFeedBack("Sosyal Medya Linkleri başarıyla güncellendi");
        } else {
          setFeedBack("Sosyal Medya Linkleri güncellenirken bir hata oluştu");
        }

      },
      //  validationSchema,
    });

  return (
    <div>
      <h2>Sosyal Medya Linkleri</h2>
      <div className={styles.SocialLinksForm}>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          {[
            { name: "txt1", placeholder: "Instagram" },
            { name: "txt2", placeholder: "Twitter" },
            { name: "txt3", placeholder: "Facebook" },
            { name: "txt4", placeholder: "Linkedin" },
            { name: "txt5", placeholder: "Youtube" },
            { name: "txt6", placeholder: "Pinterest" },
            { name: "txt7", placeholder: "Tiktok" },
            { name: "txt8", placeholder: "Reddit" },
            { name: "txt9", placeholder: "Whatsapp" },
            { name: "txt10", placeholder: "Discord" },
          ].map((input) => (
            <div key={input.name}>
              <TextInput
                value={values[input.name as keyof typeof values]}
                name={input.name}
                placeholder={input.placeholder}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors[input.name as keyof typeof errors] &&
                touched[input.name as keyof typeof touched] && (
                  <div className="error">
                    {errors[input.name as keyof typeof errors]?.toString()}
                  </div>
                )}
            </div>
          ))}

          <div className={styles.btn}>
            <Button onClick={handleSubmit} name={"Kaydet"} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SocialLinksForm;
