import React from "react";

const DOMAIN = process.env.REACT_APP_IS_DEV
  ? process.env.REACT_APP_DOMAIN_DEV
  : process.env.REACT_APP_DOMAIN_PROD;

//check if the domain (url) has a subdomain
const csd = () => {
  try {
    const fullUrl = window.location.href;
    const splittedArr = fullUrl.split("." + DOMAIN);
    if (splittedArr.length === 1) {
      // no subdomain
      return "no sub domain";
    } else if (splittedArr.length === 2) {
      // subdomain exists
      const preDomain = splittedArr[0];
      const subDomain = preDomain.split("//")[1];
      return subDomain;
    }
  } catch (err) {
    return "€ℝℝøℝ";
  }
};

export { csd };
