// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_place__8g1NC {
  height: 12vh;
}

/* .container{
          
} */

.styles_text__qeInF {
  text-align: center;
}

.styles_icons__GJXxi {
  display: flex;
  justify-content: space-between;
  margin: 3vmin 10vw 7vmin 10vw;
  width: -moz-fit-content;
  width: fit-content;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/features/site1/layouts/ContactInfo/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;;GAEG;;AAEH;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,6BAA6B;EAC7B,uBAAkB;EAAlB,kBAAkB;EAClB,SAAS;EACT,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".place {\r\n  height: 12vh;\r\n}\r\n\r\n/* .container{\r\n          \r\n} */\r\n\r\n.text {\r\n  text-align: center;\r\n}\r\n\r\n.icons {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin: 3vmin 10vw 7vmin 10vw;\r\n  width: fit-content;\r\n  gap: 2rem;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"place": `styles_place__8g1NC`,
	"text": `styles_text__qeInF`,
	"icons": `styles_icons__GJXxi`
};
export default ___CSS_LOADER_EXPORT___;
