import React, { useState, useEffect, useContext } from "react";
import { getHttps } from "../../../../hooks/useHttps";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import ProductListTitle from "../../components/ProductListTitle";
import PageTitle from "../../components/PageTitle";
import ProductCard from "../../components/ProductCard";
import styles from "./style.module.css";

function Urunler() {
  const { workplace } = useContext(WorkplaceContext);
  const [product, setProduct] = useState([
    { name: "", price: 0, image: "https://via.placeholder.com/150" },
  ]);

  const fetchProductData = async () => {
    if (workplace.id === "") return;
    const data = await getHttps("/product/get-all", {
      workplaceId: workplace.id,
    });
    setProduct(data.products);
  };
  console.log(product);
  useEffect(() => {
    fetchProductData();
  }, [workplace]);
  if (product.length === 0) return <div>Yükleniyor...</div>;
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <PageTitle title="Ürünler" fontSize="30px" />
        <ProductListTitle title="Baslangic" color="black" fontSize="20px" />
      </div>
      <div className={styles.Cards}>
        {product.map((product, index) => (
          <ProductCard
            image={
              product !== undefined && product !== null
                ? product.image !== undefined && product.image !== null
                  ? product.image !== ""
                    ? (process.env.REACT_APP_IS_DEV
                        ? process.env.REACT_APP_BACKEND_URL_DEV
                        : process.env.REACT_APP_BACKEND_URL_PROD) +
                      product.image.substring(8)
                    : "https://via.placeholder.com/1080"
                  : "https://via.placeholder.com/1080"
                : "https://via.placeholder.com/1080"
            }
            alt="product"
            key={index}
            backgroundColor="white"
            productName={product.name}
            productPrice={product.price + "tl"}
            width="360px"
            height="120px"
            fontSize="20px"
            onClick={() => console.log("clicked")}
          />
        ))}
      </div>
    </div>
  );
}

export default Urunler;
