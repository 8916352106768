import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import styles from "./style.module.css";

// import required modules
import { Pagination, Navigation, Keyboard } from "swiper/modules";

interface SliderProps {
  images: string[];
  alt: string;
  width?: string;
  height?: string;
}

function Slider({ images, alt, width, height }: SliderProps) {
  return (
    <>
      <div className={styles.container}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          keyboard={{
            enabled: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className={styles.swiper}
          style={{ width, height }}
        >
          {images.map((image, index) => {
            console.log(image);
            console.log(
              (process.env.REACT_APP_IS_DEV
                ? process.env.REACT_APP_BACKEND_URL_DEV
                : process.env.REACT_APP_BACKEND_URL_PROD) + image.substring(8)
            );
            return (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <img
                  src={
                    (process.env.REACT_APP_IS_DEV
                      ? process.env.REACT_APP_BACKEND_URL_DEV
                      : process.env.REACT_APP_BACKEND_URL_PROD) +
                    image.substring(8)
                  }
                  className={styles.swiperSlideImg}
                  alt={alt}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default Slider;
