import React, { useContext } from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import ContactMap from "../../components/ContactMap";
import ContactText from "../../components/ContactText";
import SocialIcon from "../../components/SocialIcon";
import {
  faInstagram,
  faXTwitter,
  faFacebook,
  faLinkedin,
  faYoutube,
  faPinterest,
  faTiktok,
  faReddit,
  faWhatsapp,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";

import styles from "./styles.module.css";

function Contact() {
  const { workplace } = useContext(WorkplaceContext);

  return (
    <div className={styles.bigContainer}>
      <div className={styles.contact}>
        <div className={styles.container}>
          <div className={styles.topInfo}>
            <span className={styles.info}>
              <ContactText color="white" text="Adres" fontSize="5vmin" />
              <div className={styles.adres}>
                <ContactText
                  color="white"
                  text={workplace.address}
                  fontSize="3vmin"
                />
              </div>
            </span>
          </div>
          <div className={styles.map}>
            <ContactMap address={workplace.address} />{" "}
          </div>
        </div>
        <div className={styles.rightInfo}>
          <ContactText color="white" text="Iletisim" fontSize="5vmin" />
          <ContactText color="white" text={workplace.num} fontSize="3vmin" />
          <ContactText color="white" text={workplace.email} fontSize="3vmin" />
          <div className={styles.icons}>
            {workplace.socialMediaLinks &&
              workplace.socialMediaLinks.map((link, index) => {
                if (link !== null && link !== "") {
                  const socialTypes = [
                    faInstagram,
                    faXTwitter,
                    faFacebook,
                    faLinkedin,
                    faYoutube,
                    faPinterest,
                    faTiktok,
                    faReddit,
                    faWhatsapp,
                    faDiscord,
                  ];
                  return (
                    <SocialIcon
                      size={window.innerWidth < 768 ? "2x" : "3x"}
                      socialType={socialTypes[index]}
                      socialLink={link}
                      color="white"
                      key={index}
                    />
                  );
                }
              })}
          </div>
        </div>
        <div className={styles.spaceD}></div>
      </div>
    </div>
  );
}

export default Contact;
