// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_desc__bRP1C{
    color:black;
    text-align: left;
    width: 100%;
    overflow-y: auto;
    font-family: "../../assets/fonts/Rakkas/Rakkas-Regular.ttf";
     
    display: flex;
    align-self: center;
}
.styles_container__i4Rjo {
    max-height: 300px; /* Yüksekliği ayarlayarak kaydırma çubuğu oluştur */
    overflow-y: auto; /* Dikey kaydırma çubuğu */
    border-radius: 8px; /* Köşe yuvarlama */
    padding: 10px; /* İç boşluk */
}

.styles_container__i4Rjo::-webkit-scrollbar {
    width: 8px; /* Kaydırma çubuğu genişliği */
}

.styles_container__i4Rjo::-webkit-scrollbar-track {
    background: #f1f1f1; /* Kaydırma çubuğunun arka planı */
    border-radius: 10px;
}

.styles_container__i4Rjo::-webkit-scrollbar-thumb {
    background: #888; /* Kaydırma çubuğunun rengi */
    border-radius: 10px;
}

.styles_container__i4Rjo::-webkit-scrollbar-thumb:hover {
    background: #555; /* Hover durumu için rengi */
}`, "",{"version":3,"sources":["webpack://./src/features/site2/components/DescriptionBox/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,2DAA2D;;IAE3D,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,iBAAiB,EAAE,mDAAmD;IACtE,gBAAgB,EAAE,0BAA0B;IAC5C,kBAAkB,EAAE,mBAAmB;IACvC,aAAa,EAAE,cAAc;AACjC;;AAEA;IACI,UAAU,EAAE,8BAA8B;AAC9C;;AAEA;IACI,mBAAmB,EAAE,kCAAkC;IACvD,mBAAmB;AACvB;;AAEA;IACI,gBAAgB,EAAE,6BAA6B;IAC/C,mBAAmB;AACvB;;AAEA;IACI,gBAAgB,EAAE,4BAA4B;AAClD","sourcesContent":[".desc{\r\n    color:black;\r\n    text-align: left;\r\n    width: 100%;\r\n    overflow-y: auto;\r\n    font-family: \"../../assets/fonts/Rakkas/Rakkas-Regular.ttf\";\r\n     \r\n    display: flex;\r\n    align-self: center;\r\n}\r\n.container {\r\n    max-height: 300px; /* Yüksekliği ayarlayarak kaydırma çubuğu oluştur */\r\n    overflow-y: auto; /* Dikey kaydırma çubuğu */\r\n    border-radius: 8px; /* Köşe yuvarlama */\r\n    padding: 10px; /* İç boşluk */\r\n}\r\n\r\n.container::-webkit-scrollbar {\r\n    width: 8px; /* Kaydırma çubuğu genişliği */\r\n}\r\n\r\n.container::-webkit-scrollbar-track {\r\n    background: #f1f1f1; /* Kaydırma çubuğunun arka planı */\r\n    border-radius: 10px;\r\n}\r\n\r\n.container::-webkit-scrollbar-thumb {\r\n    background: #888; /* Kaydırma çubuğunun rengi */\r\n    border-radius: 10px;\r\n}\r\n\r\n.container::-webkit-scrollbar-thumb:hover {\r\n    background: #555; /* Hover durumu için rengi */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desc": `styles_desc__bRP1C`,
	"container": `styles_container__i4Rjo`
};
export default ___CSS_LOADER_EXPORT___;
