import axios from "axios";
axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL_PROD;

const postHttps = async (url: string, data: any) => {
  try {
    const resData = await axios.post(API_URL + url, data);
    return resData.data;
  } catch (err) {
    console.log(err);
    return { status: "fail" };
  }
};

const getHttps = async (url: string, data?: any) => {
  try {
    const resData = await axios.get(API_URL + url, { params: data });
    return resData.data;
  } catch (err) {
    return { status: "fail" };
  }
};

const deleteHttps = async (url: string, data: any) => {
  try {
    const resData = await axios.delete(API_URL + url, { data: data });
    return resData.data;
  } catch (err) {
    return { status: "fail" };
  }
};

const putHttps = async (url: string, data: any) => {
  try {
    const resData = await axios.put(API_URL + url, data);
    console.log("resData", resData);
    return resData.data;
  } catch (err) {
    // console.log(err);
    return { status: "fail" };
  }
};

export { postHttps, getHttps, deleteHttps, putHttps };
