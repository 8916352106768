import React, {useContext} from "react";
import { Routes, Route } from "react-router-dom";
import WorkplaceContext from "../../context/workplace/WorkplaceContext";

import PagesNavbar from "../site2/layouts/PagesNavbar";
import AboutNavbar from "../site2/layouts/AboutNavbar";
import NavbarHome from "../site2/layouts/NavbarHome";


function NavbarRouter() {
  const { workplace, changeWorkplace } = useContext(WorkplaceContext);

  return (
    <div>
        <Routes>
          <Route path="/" element={<NavbarHome backgroundColor={workplace.colors[0]} />} />
          <Route path="/about" element={<AboutNavbar backgroundColor={workplace.colors[0]} />} />
          <Route path="/categories" element={<PagesNavbar backgroundColor={workplace.colors[0]} title="Menu" />} />
          <Route path="/products" element={<PagesNavbar backgroundColor={workplace.colors[0]} title="Menu" />} />
          <Route path="/contact" element={<PagesNavbar backgroundColor={workplace.colors[0]} title="Menu" />} />
        </Routes>
    </div>
  );
}

export default NavbarRouter;
