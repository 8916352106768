import React from "react";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import NavbarItem from "../../components/NavbarItem";
import NavbarLogo from "../../components/NavbarLogo";
import NavbarMenuIcon from "../../components/NavbarMenuIcon";

import styles from "./style.module.css";

interface NavbarProps {
  color?: string;
  backgroundColor: string;
}

function Navbar({ color, backgroundColor }: NavbarProps) {
  const { workplace } = React.useContext(WorkplaceContext);
  const pages = [
    {
      text: "ANASAYFA",
      fontSize: "1.25rem",
      url: "/",
      // onClick: () => console.log("clicked"),
    },
    {
      text: "HAKKINDA",
      fontSize: "1.25rem",
      url: "/about",
      // onClick: () => console.log("clicked"),
    },
    {
      text: "KATEGORİLER",
      fontSize: "1.25rem",
      url: "/categories",
      // onClick: () => console.log("clicked"),
    },
    {
      text: "ÜRÜNLER",
      fontSize: "1.25rem",
      url: "/products",
      // onClick: () => console.log("clicked"),
    },
    {
      text: "İLETİŞİM",
      fontSize: "1.25rem",
      url: "/contact",
      // onClick: () => console.log("clicked"),
    },
  ];
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  return (
    <nav style={{ backgroundColor }} className={styles.navbarBox}>
      <div className={styles.navbarLogo}>
        <NavbarLogo
          isSquare={false}
          image={
            workplace !== undefined && workplace !== null
              ? workplace.logo !== undefined && workplace.logo !== null
                ? workplace.logo !== ""
                  ? ( process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL_PROD ) +
                    workplace.logo.substring(8)
                  : "https://via.placeholder.com/1080"
                : "https://via.placeholder.com/1080"
              : "https://via.placeholder.com/1080"
          }
          height="6em"
          width="6em"
          alt="logo-512x512"
          onClick={() => {}}
        />
      </div>
      <div className={styles.line} />
      <div className={styles.navbarIcon} >
        <NavbarMenuIcon
          color="white"
          size="lg"
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen);
          }}
        />
      </div>
      <div
        style={{
          display: mobileNavOpen || window.innerWidth > 767 ? "flex" : "none",
        }}
        className={styles.navbarItems}
      >
        {pages.map((page, index) => (
          <a key={index}>
            <NavbarItem
              text={page.text}
              // fontSize={page.fontSize}
              fontSize={window.innerWidth > 1600 ? page.fontSize : "1rem"}
              url={page.url}
            />
          </a>
        ))}
      </div>
    </nav>
  );
}

export default Navbar;
