// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card__As51d {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  justify-items: center;
  position: relative;
  margin: 1rem;
  border: 1px solid #d9b99b;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: white;
  max-width: 300px; 
  /* width: 300px;  */
  height: 400px;
  
}

.style_cardImage__x6gvY {
  width: 100%;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;
}

.style_cardImage__x6gvY img {
  width: 100%;
  max-height: 300px;
  min-height: 275px;
  object-fit:fill;
}

.style_info__Xc1Va {
  display: grid;
  gap: 0.5rem;
  padding: 1rem;
}

.style_title__TGrl- {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.style_price__fSI09 {
  color: #a36446;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  margin: 0;
}

/* Responsive ayar */
@media (min-width: 768px) {
  .style_card__As51d {
    max-width: 400px;
  }
}

@media (min-width: 1024px) {
  .style_card__As51d {
    max-width: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/site2/components/ProductCard/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,uCAAuC;EACvC,yBAAyB;EACzB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;;AAEf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,aAAa;AACf;;AAEA;EACE,4CAA4C;EAC5C,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,SAAS;AACX;;AAEA,oBAAoB;AACpB;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".card {\r\n  display: grid;\r\n  grid-template-rows: auto 1fr;\r\n  align-items: center;\r\n  justify-items: center;\r\n  position: relative;\r\n  margin: 1rem;\r\n  border: 1px solid #d9b99b;\r\n  border-radius: 1rem;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  transition: all 0.3s ease;\r\n  background-color: white;\r\n  max-width: 300px; \r\n  /* width: 300px;  */\r\n  height: 400px;\r\n  \r\n}\r\n\r\n.cardImage {\r\n  width: 100%;\r\n  overflow: hidden;\r\n  border-radius: 1rem 1rem 0 0;\r\n}\r\n\r\n.cardImage img {\r\n  width: 100%;\r\n  max-height: 300px;\r\n  min-height: 275px;\r\n  object-fit:fill;\r\n}\r\n\r\n.info {\r\n  display: grid;\r\n  gap: 0.5rem;\r\n  padding: 1rem;\r\n}\r\n\r\n.title {\r\n  font-family: 'Times New Roman', Times, serif;\r\n  font-size: 1.2rem;\r\n  font-weight: bold;\r\n  text-align: center;\r\n  margin: 0;\r\n}\r\n\r\n.price {\r\n  color: #a36446;\r\n  font-weight: bold;\r\n  font-size: 1rem;\r\n  text-align: center;\r\n  margin: 0;\r\n}\r\n\r\n/* Responsive ayar */\r\n@media (min-width: 768px) {\r\n  .card {\r\n    max-width: 400px;\r\n  }\r\n}\r\n\r\n@media (min-width: 1024px) {\r\n  .card {\r\n    max-width: 500px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `style_card__As51d`,
	"cardImage": `style_cardImage__x6gvY`,
	"info": `style_info__Xc1Va`,
	"title": `style_title__TGrl-`,
	"price": `style_price__fSI09`
};
export default ___CSS_LOADER_EXPORT___;
