// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__dWgsC {
    justify-content: center;
    padding: 1rem 0rem;
}

.styles_formContainer__IKepe {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;

}


.styles_btn__L0DPe {
    margin: 0 auto;
}

.styles_success__JDTXb {
    display: flex;
    position: fixed;
    top: 10px;
    left: 50%;
    font-size: 1.5rem;
    transform: translateX(-50%);
    background-color: #1976D299;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    /* Diğer elementlerin üzerine gelsin */
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    width: 100%;
    height: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/CategoryForm/styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,cAAc;;AAElB;;;AAGA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,SAAS;IACT,iBAAiB;IACjB,2BAA2B;IAC3B,2BAA2B;IAC3B,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,0CAA0C;IAC1C,aAAa;IACb,sCAAsC;IACtC,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,kCAAkC;IAClC,WAAW;IACX,UAAU;AACd","sourcesContent":[".title {\r\n    justify-content: center;\r\n    padding: 1rem 0rem;\r\n}\r\n\r\n.formContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 75%;\r\n    margin: 0 auto;\r\n\r\n}\r\n\r\n\r\n.btn {\r\n    margin: 0 auto;\r\n}\r\n\r\n.success {\r\n    display: flex;\r\n    position: fixed;\r\n    top: 10px;\r\n    left: 50%;\r\n    font-size: 1.5rem;\r\n    transform: translateX(-50%);\r\n    background-color: #1976D299;\r\n    color: #fff;\r\n    padding: 1rem;\r\n    border-radius: 5px;\r\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);\r\n    z-index: 9999;\r\n    /* Diğer elementlerin üzerine gelsin */\r\n    text-align: center;\r\n    justify-content: center;\r\n    align-items: center;\r\n    opacity: 1;\r\n    transition: opacity 1s ease-in-out;\r\n    width: 100%;\r\n    height: 5%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__dWgsC`,
	"formContainer": `styles_formContainer__IKepe`,
	"btn": `styles_btn__L0DPe`,
	"success": `styles_success__JDTXb`
};
export default ___CSS_LOADER_EXPORT___;
