// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__iyDEV {
  /* position: relative; */
  display: flex;
  align-self: center;
  align-items: center;
  background-color: transparent;
  /* margin: 0; */
}

.style_logo__Rm\\+Lx {
  display: block;
  justify-content: center;
  width: 85%;
  height: 85%;
  margin-left: 10%;
  background-color: transparent;
  /* -moz-border-radius: 50%;
    -webkit-border-radius: 50%; */
  border-radius: 50%;
  /* margin: 0; */

}
`, "",{"version":3,"sources":["webpack://./src/features/site2/components/NavbarLogo/style.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,6BAA6B;EAC7B;iCAC+B;EAC/B,kBAAkB;EAClB,eAAe;;AAEjB","sourcesContent":[".container {\r\n  /* position: relative; */\r\n  display: flex;\r\n  align-self: center;\r\n  align-items: center;\r\n  background-color: transparent;\r\n  /* margin: 0; */\r\n}\r\n\r\n.logo {\r\n  display: block;\r\n  justify-content: center;\r\n  width: 85%;\r\n  height: 85%;\r\n  margin-left: 10%;\r\n  background-color: transparent;\r\n  /* -moz-border-radius: 50%;\r\n    -webkit-border-radius: 50%; */\r\n  border-radius: 50%;\r\n  /* margin: 0; */\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__iyDEV`,
	"logo": `style_logo__Rm+Lx`
};
export default ___CSS_LOADER_EXPORT___;
