import React, { useState, useContext } from "react";
import { SketchPicker } from "react-color";
import styles from "./styles.module.css";
import WorkplaceContext from "../../context/workplace/WorkplaceContext";

interface ColorPickerProps {
  colorArray: string[];
  setColorArray: (colorArray: string[]) => void;
}

function ColorPicker({ colorArray, setColorArray }: ColorPickerProps) {
  const [selectedBox, setSelectedBox] = useState("");
  const { workplace } = useContext(WorkplaceContext);

  const handleChangeComplete = (colorObj: any) => {
    setColorArray([
      selectedBox === "0" ? colorObj.hex : colorArray[0],
      selectedBox === "1" ? colorObj.hex : colorArray[1],
      selectedBox === "2" ? colorObj.hex : colorArray[2],
      selectedBox === "3" ? colorObj.hex : colorArray[3],
      selectedBox === "4" ? colorObj.hex : colorArray[4],
    ]);
    if (selectedBox) {
      const selectedElement = document.getElementById(selectedBox);
      if (selectedElement) {
        selectedElement.style.backgroundColor = colorObj.hex;
      }
    }
  };

  return (
    <div>
      <h1 className={styles.title}>Renk Ayarlari</h1>
      <div className={styles.container}>
        <SketchPicker color={"#fff"} onChangeComplete={handleChangeComplete} />
        
        {/* Use workplace.color or fallback to default colors */}
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            key={index}
            className={styles.box}
            id={String(index)}
            onClick={() => setSelectedBox(String(index))}
            style={{ backgroundColor: workplace?.colors?.[index] || getDefaultColor(index) }}
          >
            Box {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
}

// Function to get default colors based on index
const getDefaultColor = (index: number) => {
  const defaultColors = ["#4d58ff", "#06588f", "#ff0037", "#cdc2ff", "#5278a8"];
  return defaultColors[index] || "#4d58ff"; // Fallback to first default color
}

export default ColorPicker;
