import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useFormik } from "formik";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { getHttps, postHttps, putHttps } from "../../hooks/useHttps";

interface TextBlocksFormProps {
  workplace: string;
  setFeedBack: any;
  default: string[];
}

function TextBlocksForm({ workplace, setFeedBack, default: defaultTexts }: TextBlocksFormProps) {
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        txt1: defaultTexts[0] ? defaultTexts[0] : "",
        txt2: defaultTexts[1] ? defaultTexts[1] : "",
        txt3: defaultTexts[2] ? defaultTexts[2] : "",
        txt4: defaultTexts[3] ? defaultTexts[3] : "",
        txt5: defaultTexts[4] ? defaultTexts[4] : "",
        txt6: defaultTexts[5] ? defaultTexts[5] : "",
        txt7: defaultTexts[6] ? defaultTexts[6] : "",
        txt8: defaultTexts[7] ? defaultTexts[7] : "",
        txt9: defaultTexts[8] ? defaultTexts[8] : "",
        txt10: defaultTexts[9] ? defaultTexts[9] : "",
      },
      onSubmit: async (values: any) => {
        const textBlocksArray = Object.values(values);
        // console.log("textBlocksArray", textBlocksArray);

        const result = await putHttps("/workplace/set-texts", {
          id: workplace,
          TextBlocks: textBlocksArray,
        });
        if (result.status === "success") {
          setFeedBack("Metinler başarıyla güncellendi");
        } else {
          setFeedBack("Metinler güncellenirken bir hata oluştu");
        }

        // console.log("Update Product", result);
      },
      //  validationSchema,
    });

  return (
    <div>
      <h2>Metinler</h2>
      <div className={styles.TextBlocksForm}>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          {[
            { name: "txt1", placeholder: "metin 1" },
            { name: "txt2", placeholder: "metin 2" },
            { name: "txt3", placeholder: "metin 3" },
            { name: "txt4", placeholder: "metin 4" },
            { name: "txt5", placeholder: "metin 5" },
            { name: "txt6", placeholder: "metin 6" },
            { name: "txt7", placeholder: "metin 7" },
            { name: "txt8", placeholder: "metin 8" },
            { name: "txt9", placeholder: "metin 9" },
            { name: "txt10", placeholder: "metin 10" },
          ].map((input) => (
            <div key={input.name}>
              <TextInput
                value={values[input.name as keyof typeof values]}
                name={input.name}
                placeholder={input.placeholder}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors[input.name as keyof typeof errors] &&
                touched[input.name as keyof typeof touched] && (
                  <div className="error">
                    {errors[input.name as keyof typeof errors]?.toString()}
                  </div>
                )}
            </div>
          ))}

          <div className={styles.btn}>
            <Button onClick={handleSubmit} name={"Kaydet"} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default TextBlocksForm;
