import React, { useState, useEffect, useContext } from "react";
import { getHttps } from "../../../../hooks/useHttps";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";

import ContactMap from "../../components/ContactMap";
import ContactText from "../../components/ContactText";
import PlayIcon from "../../components/PlayIcon";
import ContactInfo from "../../layouts/ContactInfo";

import styles from "./styles.module.css";

function Contact() {
  const { workplace } = useContext(WorkplaceContext);

  return (
    <div className={styles.contact}>
      <div className={styles.title}>
        <div className={styles.play}>
          <PlayIcon color="black" size="lg" />
        </div>
        <ContactText isTitle text="İletişim Bilgileri" fontSize="5vmin" />
      </div>
      <div className={styles.container}>
        <ContactMap
          address={workplace.address}
        />
        <div className={styles.infos}>
          <span className={styles.info}>
            <ContactText text="Adres" isTitle fontSize="4vmin" />
            <ContactText text={workplace.address} fontSize="3vmin" />
          </span>
          <span className={styles.info}>
            <ContactText text="Telefon Numarası" isTitle fontSize="4vmin" />
            <ContactText text={workplace.num} fontSize="3vmin" />
          </span>
          <span className={styles.info}>
            <ContactText text="Mail Adresi" isTitle fontSize="4vmin" />
            <ContactText text={workplace.email} fontSize="3vmin" />
          </span>
        </div>
      </div>
      <div>
        <ContactInfo />
      </div>
      <div className={styles.spaceD}></div>
    </div>
  );
}

export default Contact;
