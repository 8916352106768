// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_image__skyz5 {
    width: 80%; /* Resmin genişliğini tam kapsayacak şekilde ayarlayın */
    height: 90%;
    height: auto; /* Orantılı yükseklik */
    object-fit: cover; /* Resmin düzgün görünmesi için */
    
    /* Dış gölge */
    box-shadow: 
    -5px 4px 6px rgba(0, 0, 0, 0.25), /* Sol gölge */
    0px 5px 6px rgba(0, 0, 0, 0.25); /* Alt gölge */
    
    /* İç gölgeler için pseudo-element */
    position: relative;
    overflow: hidden; /* İç gölgelerin düzgün görünmesi için */
  }
  

.style_image__skyz5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 
      8px 8px 5px rgba(0, 0, 0, 0.5), 
      -10px -7px 13px rgba(0, 0, 0, 0.5); 
  }`, "",{"version":3,"sources":["webpack://./src/features/site2/components/IntroductionImage/style.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,wDAAwD;IACpE,WAAW;IACX,YAAY,EAAE,uBAAuB;IACrC,iBAAiB,EAAE,iCAAiC;;IAEpD,cAAc;IACd;;mCAE+B,EAAE,cAAc;;IAE/C,oCAAoC;IACpC,kBAAkB;IAClB,gBAAgB,EAAE,wCAAwC;EAC5D;;;AAGF;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT;;wCAEoC;EACtC","sourcesContent":[".image {\r\n    width: 80%; /* Resmin genişliğini tam kapsayacak şekilde ayarlayın */\r\n    height: 90%;\r\n    height: auto; /* Orantılı yükseklik */\r\n    object-fit: cover; /* Resmin düzgün görünmesi için */\r\n    \r\n    /* Dış gölge */\r\n    box-shadow: \r\n    -5px 4px 6px rgba(0, 0, 0, 0.25), /* Sol gölge */\r\n    0px 5px 6px rgba(0, 0, 0, 0.25); /* Alt gölge */\r\n    \r\n    /* İç gölgeler için pseudo-element */\r\n    position: relative;\r\n    overflow: hidden; /* İç gölgelerin düzgün görünmesi için */\r\n  }\r\n  \r\n\r\n.image::before {\r\n    content: '';\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    box-shadow: \r\n      8px 8px 5px rgba(0, 0, 0, 0.5), \r\n      -10px -7px 13px rgba(0, 0, 0, 0.5); \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `style_image__skyz5`
};
export default ___CSS_LOADER_EXPORT___;
