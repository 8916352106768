import React, { useState, useEffect, useContext } from "react";
import { getHttps } from "../../../../hooks/useHttps";
import WorkplaceContext from "../../../../context/workplace/WorkplaceContext";
import ProductCard from "../../components/ProductCard";
import styles from "./style.module.css";

function Urunler() {
  const { workplace } = useContext(WorkplaceContext);
  const [products, setProducts] = useState<any[]>([]); // Tüm ürünleri tutacak
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]); // Filtrelenen ürünleri tutacak
  const categoryName = decodeURIComponent(window.location.hash.slice(1)); // URL'deki kategori adı
  const [categoryId, setCategoryId] = useState<string>("");

  // Kategorinin ID'sini alıyoruz
  const fetchCategoryId = async (categoryName: string) => {
    const data = await getHttps("/category/get-category-id-by-name", {
      categoryName: categoryName,
    });
    setCategoryId(data.category);
  };

  // Ürünleri alıyoruz
  const fetchProductData = async () => {
    const data = await getHttps("/product/get-all", {
      workplaceId: workplace.id,
    });
    setProducts(data.products);
  };

  useEffect(() => {
    fetchProductData();
    if (categoryName) {
      fetchCategoryId(categoryName); // Eğer kategori varsa ID'yi alıyoruz
    }
  }, [categoryName, workplace]);

  useEffect(() => {
    if (categoryId) {
      // Eğer kategori varsa, sadece o kategorinin ürünlerini filtreliyoruz
      const filtered = products.filter(
        (product) => product.category === categoryId
      );
      setFilteredProducts(filtered);
    } else {
      // Kategori seçilmemişse, tüm ürünleri listeliyoruz
      setFilteredProducts(products);
    }
  }, [categoryId, products]);

  return (
    <div className={styles.containergp}>
      <h1 className={styles.categoryName}>
        {categoryName ? categoryName : "All Products"}
      </h1>
      {filteredProducts.length > 0 ? (
        <div className={styles.Cards}>
          <div className={styles.CardsLeft}>
            {filteredProducts.map((product, index) => (
              <div key={index} className={styles.card}>
                <ProductCard
                  title={product.name}
                  price={product.price}
                  alt={`${index}.Photo`}
                  src={
                    product !== undefined && product !== null
                    ? product.image !== undefined && product.image !== null
                      ? product.image !== ""
                        ? ( process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : process.env.REACT_APP_BACKEND_URL_PROD ) +
                          product.image.substring(8)
                        : "https://via.placeholder.com/1080"
                      : "https://via.placeholder.com/1080"
                    : "https://via.placeholder.com/1080"
                  }
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>No products available.</p>
      )}
    </div>
  );
}

export default Urunler;
