// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__W-SMO{
    justify-content: center;
    padding: 1rem 0rem;
}

.styles_productForm__7Iuwr{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.styles_formContainer__ZK0am{
    display: flex;
    flex-direction: column;
    width: 60%;
}


.styles_btn__VWtJT{
    margin:0 auto;
}

.styles_input__4Pk6p{
    cursor: pointer;
    
}

.styles_success__grqIu {
    display: flex;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: darkslategray;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    /* Diğer elementlerin üzerine gelsin */
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    width: 10%;
    height: 7%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/ProductForm/styles.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;;AAEnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,+BAA+B;IAC/B,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,0CAA0C;IAC1C,aAAa;IACb,sCAAsC;IACtC,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,oCAAoC;IACpC,UAAU;IACV,UAAU;AACd","sourcesContent":[".title{\r\n    justify-content: center;\r\n    padding: 1rem 0rem;\r\n}\r\n\r\n.productForm{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.formContainer{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 60%;\r\n}\r\n\r\n\r\n.btn{\r\n    margin:0 auto;\r\n}\r\n\r\n.input{\r\n    cursor: pointer;\r\n    \r\n}\r\n\r\n.success {\r\n    display: flex;\r\n    position: fixed;\r\n    top: 10px;\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n    background-color: darkslategray;\r\n    color: #fff;\r\n    padding: 1rem;\r\n    border-radius: 5px;\r\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);\r\n    z-index: 9999;\r\n    /* Diğer elementlerin üzerine gelsin */\r\n    text-align: center;\r\n    justify-content: center;\r\n    align-items: center;\r\n    opacity: 1;\r\n    transition: opacity 0.5s ease-in-out;\r\n    width: 10%;\r\n    height: 7%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__W-SMO`,
	"productForm": `styles_productForm__7Iuwr`,
	"formContainer": `styles_formContainer__ZK0am`,
	"btn": `styles_btn__VWtJT`,
	"input": `styles_input__4Pk6p`,
	"success": `styles_success__grqIu`
};
export default ___CSS_LOADER_EXPORT___;
