import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { deleteHttps } from "../../hooks/useHttps";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Button from "../Button";

import styles from "./styles.module.css";

// import required modules
import { Pagination, Navigation, Keyboard } from "swiper/modules";

interface ShowImagesProps {
  images: string[];
  alt: string;
  workplace?: string;
  width?: string;
  height?: string;
  setFeedBack?: any;
}

function ShowImages({
  images,
  alt,
  width,
  height,
  workplace,
  setFeedBack,
}: ShowImagesProps) {
  const onDelete = async (img: string) => {
    console.log("Delete clicked", img);
    const response = await deleteHttps("/workplace/delete-image", {
      imageName: img,
      workplaceId: workplace,
    });
    // console.log("delete image response: ", response);

    if (response.status === "success") {
      setFeedBack("Resim başarıyla silindi.");
    } else {
      setFeedBack("Resim silinirken hata oluştu.");
    }
  };

  return (
    <>
      <div className={styles.container}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          keyboard={{
            enabled: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className={styles.swiper}
          style={{ width, height }}
        >
          {images.map((image, index) => {
            return (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <img
                  src={
                    (process.env.REACT_APP_IS_DEV
                      ? process.env.REACT_APP_BACKEND_URL_DEV
                      : process.env.REACT_APP_BACKEND_URL_PROD) +
                    image.substring(8)
                  }
                  className={styles.swiperSlideImg}
                  alt={alt}
                />
                <Button
                  name={"Delete Image"}
                  onClick={() => {
                    onDelete(image);
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default ShowImages;
