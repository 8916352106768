// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .container{
    display: flex;
    flex-direction: row;
    gap: 5rem;
    justify-content: center;
    margin-bottom: 3rem;
}

.box{
    border-radius: 10%;
    gap: 5rem;
 
}
#box1{
    width: 5rem;
    height: 1rem;
}
.title{
    text-align: center;
    margin:0rem 1rem 3rem 1rem;
    align-items: center;
    justify-content: center;
    
} */

.styles_container__V8G7U {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    gap: 2.5rem; 
  }
  
  .styles_box__YYcYm{
    border: 1px solid #333;
    margin: 1rem;
    padding: 1rem;
  }

  .styles_title__Ce1\\+o{
    text-align: center;
    align-items: center;
    justify-content: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/ColorPicker/styles.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;GAuBG;;AAEH;IACI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":["/* .container{\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 5rem;\r\n    justify-content: center;\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.box{\r\n    border-radius: 10%;\r\n    gap: 5rem;\r\n \r\n}\r\n#box1{\r\n    width: 5rem;\r\n    height: 1rem;\r\n}\r\n.title{\r\n    text-align: center;\r\n    margin:0rem 1rem 3rem 1rem;\r\n    align-items: center;\r\n    justify-content: center;\r\n    \r\n} */\r\n\r\n.container {\r\n    display: flex;\r\n    /* flex-wrap: wrap; */\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 2.5rem; \r\n  }\r\n  \r\n  .box{\r\n    border: 1px solid #333;\r\n    margin: 1rem;\r\n    padding: 1rem;\r\n  }\r\n\r\n  .title{\r\n    text-align: center;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__V8G7U`,
	"box": `styles_box__YYcYm`,
	"title": `styles_title__Ce1+o`
};
export default ___CSS_LOADER_EXPORT___;
